import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { Col, Container, NavItem, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import $ from 'jquery';


function BasicExample() {
  const internationals = useNavigate()
  const[open,setOpen]=useState()
  const[open1,setOpen1]=useState()
  const[open2,setOpen2]=useState()


  const asets = useNavigate()
  // const [open, setOpen] = useState(true);
  const location = useLocation();  // const [opens, setOpens] = useState(true);
  const history = useLocation();
  return (
    <Navbar collapseOnSelect sticky='top' expand="lg" bg="dark-color" >

      <Navbar.Brand><Link to='/'><img src='/Asets/new-logo.png' /></Link> </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className='navbar-left'>
        <Nav className="navber-nav-menus">
          <NavItem > <Nav.Link href="/"> Home </Nav.Link></NavItem>
          <NavDropdown title="About Us" id="basic-nav-dropdown-1"
            show={open1}
            renderMenuOnMount={true}
            onMouseEnter={()=>{setOpen1(true)}}
            onMouseLeave={()=>setOpen1(false)}
            onClick={()=>{setOpen1(false)}}

          >
            <NavDropdown.Item  ><Link to="/page/bstlprofile">BSTL Profile </Link></NavDropdown.Item>
            {/* <NavDropdown.Item  >  <Link to="/management" >Management </Link></NavDropdown.Item> */}
            <NavDropdown.Item ><Link to="/vision"> Vision , Mission , Value </Link> </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Our Business" id="basic-nav-dropdown-1"
           show={open}
           renderMenuOnMount={true}
           onMouseEnter={()=>{setOpen(true)}}
           onMouseLeave={()=>setOpen(false)}
           onClick={()=>{setOpen(false)}}
           className='our-business-drop'

          >
            {/* <NavDropdown.Item href="/" ><Link to="/page/bstlprofile">BSTL Profile </Link></NavDropdown.Item> */}
            {/* <NavDropdown.Item  >  <Link to="/management" >Management </Link></NavDropdown.Item>
            <NavDropdown.Item ><Link to="/vision"> Vision , Mission , Value </Link> </NavDropdown.Item> */}
          <Container>
            <Row>
              <Col lg={6}>
              <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/abb_llc">  ABB LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hitach">Hitachi Energy LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hyunet">Hyunet Private Ltd </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/green">Green Tech Energy & Watter LLC  </Link>
                </a>
            <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/homeland">Homeland Plants LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blue_aqua">Blue Aqua LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blue_aqua_singarpur">Blue Aqua, Singapore</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/apollo_muscat">Apollo Hospital Muscat LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/international">International Medical Center LLC  </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/super">Apollo Super Speciality Hospital LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/amaira_retail">Ash Amaira Retail Private Limited</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/accessories">Ash Amaira Accessories Trading LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/genral">General Dynamics Mission Systems-Gulf LLC</Link>
                </a>
              </Col>
              <Col lg={6}>
             
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/gulf">Gulf Security Services LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/bahwan_lamnalco">Bahwan Lamnalco SAOC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/rosenbauer">Rosenbauer MENA FZE (Austria)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/cantiere">Cantiere Navale Vittoria S.p.A. (Italy)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/vanderlande">Vanderlande Industries B.V. (Netherlands)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/loconav">Loconav Inc. (USA)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/al_qantar">Al Qantar International SPC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/techno">Techno  Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/intelligent">Intelligent Parking  & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/north">North Ocean Industries SPC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/sadad">Sadad International LLC </Link>
                </a>
              </Col>
            </Row>
          </Container>
         
          {/* <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/abb_llc">  ABB LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hitach">Hitachi Energy LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hyunet">Hyunet Private Ltd </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/green">Green Tech Energy & Watter LLC  </Link>
                </a>
          
          
            <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/homeland">Homeland Plants LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaqua">Blue Aqua LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/apollo">Apollo Hospital Muscat LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/international">International Medical Center LLC (Sugar Apollo) </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/super">Apollo Super Speciality Hospital LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/retail">Ash Amaira Retail Private Limited</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/accessories">Ash Amaira Accessories Trading LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/general">General Dynamics Mission Systems-Gulf LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/gulf">Gulf Security Services LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/lamnalco">Bahwan Lamnalco SAOC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/rosenbauer">Rosenbauer MENA FZE (Austria)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/cantiere">Cantiere Navale Vittoria S.p.A. (Italy)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/vanderlande">Vanderlande Industries B.V. (Netherlands)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/loconav">Loconav Inc. (USA)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/woodenm">Al Qantar International SPC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/techno">Techno  Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/intelligent">Intelligent Parking  & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/north">North Ocean Industries SPC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/sadad">Sadad International LLC </Link>
                </a> */}

          </NavDropdown>


          {/* <NavDropdownMenu title="Our Business" id="collasible-nav-dropdown"
            renderMenuOnMount={true}


          >


            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/energy'}>Energy</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ABB_LLC">  ABB LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hitachi">Hitachi Energy LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/hyunet">Hyunet Private Ltd </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/green">Green Tech Energy & Watter LLC  </Link>
                </a>
               

              </div>
            </div>
            <div class="dropdown-submenu-container" >
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/food'}>Food</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/homeland">Homeland Plants LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaqua">Blue Aqua LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link>
                </a>
              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/health'}>Healthcare</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/apollo">Apollo Hospital Muscat LLC </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/international">International Medical Center LLC (Sugar Apollo) </Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/super">Apollo Super Speciality Hospital LLC </Link>
                </a>
              

              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/fashion'}>Retail and Fashion</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/retail">Ash Amaira Retail Private Limited</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/accessories">Ash Amaira Accessories Trading LLC</Link>
                </a>
              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/asset'}>Asset Security & Asset Management</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/general">General Dynamics Mission Systems-Gulf LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/gulf">Gulf Security Services LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/lamnalco">Bahwan Lamnalco SAOC</Link>
                </a>
              
              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/internatinol'}>International Representation</Link> </a>
              <div class="dropdown-menu show">

                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/rosenbauer">Rosenbauer MENA FZE (Austria)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/cantiere">Cantiere Navale Vittoria S.p.A. (Italy)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/vanderlande">Vanderlande Industries B.V. (Netherlands)</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/loconav">Loconav Inc. (USA)</Link>
                </a>

              </div>
            </div>
            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/packaging'}>Packaging & Logistic </Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/woodenm">Al Qantar International SPC</Link>
                </a>
           
              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/realestate'}>Real Estate & Projects </Link> </a>
              <div class="dropdown-menu show">

                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/techno">Techno  Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/intelligent">Intelligent Parking  & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/ipark">I Park Robotic Parking & Elevators LLC</Link>
                </a>
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/north">North Ocean Industries SPC</Link>
                </a>

              </div>
            </div>

            <div class="dropdown-submenu-container">
              <a class="dropdown-item dropdown-submenu dropdown-toggle" ><Link to={'/page/financial'}>Financial Services</Link> </a>
              <div class="dropdown-menu show">
                <a data-rr-ui-dropdown-item="" class="dropdown-item" role="button" tabindex="0" href="#">
                  <Link to="/page/sadad">Sadad International LLC </Link>
                </a>

              </div>
            </div>

          </NavDropdownMenu> */}
          <NavItem><Nav.Link onClick={() => internationals('internationals')}> International</Nav.Link></NavItem>
          <NavDropdown title="Media"
   show={open2}
   renderMenuOnMount={true}
   onMouseEnter={()=>{setOpen2(true)}}
   onMouseLeave={()=>setOpen2(false)}
   onClick={()=>{setOpen2(false)}}            
            
            >
            <NavDropdown.Item><Link to="/images">Images</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/video">Videos</Link></NavDropdown.Item>
            {/* <NavDropdown.Item><Link to="/news">News</Link></NavDropdown.Item> */}
          </NavDropdown>
          <NavItem> <Nav.Link>  <Link to={'/contact'} style={{textDecoration:'none',color:'black'}}>Contact</Link> </Nav.Link></NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default BasicExample;