import React from 'react';
import { Container ,Row,Col} from 'react-bootstrap';
import { AiFillDollarCircle } from 'react-icons/ai';
import { FaAlignRight, FaArrowCircleRight, FaArrowRight, FaChevronCircleRight, FaChevronRight, FaShopify } from 'react-icons/fa';
import { GiBoxUnpacking, GiHealingShield } from 'react-icons/gi';
import { MdApartment } from 'react-icons/md';
import { SiSpringsecurity } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { LuSalad } from "react-icons/lu";
import { GiRollingEnergy } from "react-icons/gi";
import { GiWireframeGlobe } from "react-icons/gi";




const CardFlip = () => {

    const ourbusines=[
        // { image: <GiRollingEnergy  size={'90px'} className='icon-color' />
        // ,       title: "ENERGY",   link: "/page/energy",
        // smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/ZZWCnFuyCPDo30bbj73nPUPWIudD9wvVFXMeEWuK.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/kiMXLNpRaCC10mtqA9E5g8KQ7ClBIKEX1JM05qHw.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/gCWlgWFrnLKgwmFJdvjU161EcF9oPXUS9ffDGoJP.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/iVtL6JdpOVnCptfmynnLixYgGhrJz1me2uVR1yhQ.jpg"}
        // ] },
        // { image: <LuSalad  size="95px"  className='icon-color'/>
        // ,    title: "FOOD",  link: "/page/food",smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/JIexVSEsHKYEUvoXKXNrwAiBi2gAh4ml0Ey6FglU.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/HvWpafrh3vxxMGFC8qZrNogn95AMQASSS9Hn6OnA.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/wfJ337bfo0yjvjZ5uBNe3VTujlcQ4CAAMu7JjVRW.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/mAmubchmD424S2KTFVAygpAjk4sinugJrJixFqk4.jpg"}
        // ] },
        // { image: <GiHealingShield size="95px"  className='icon-color' />,title: "HEALTHCARE ", link: "/page/health" ,smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/eierXthfcBPnfZn0a58LAgqWTaeEZaUY3aBv6HFy.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/ijZ1ibHBV2aWkEGhe1csErmGMyveCtuWw4ypFUnh.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/51iJ3Nl54hV0pemBZ2STE5RyGVvrDksKFuMVguCg.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/PiWZwdUUYKs1BIJ2QJHDfa5lnaA4uoqTWgPrd6i6.jpg"}
        // ] },
        // { image: <FaShopify size="95px"  className='icon-color'/>,title: "FASHION & RETAIL", link: "/page/fashion",
        // smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/Yk82t5rmxYmk3eCxzhiZkJFOLw3hZivdR7nBMlPY.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/XGgjjkRtxizzZapYNZMklSk0wm7xzaX8XTmbO5ga.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/MsVdNM4GsQUCii8R5qFvGj7Pwt9j4Yr3uXnMSqVW.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/z4b5eweMnofkLzTXdbuCyDDExuHmvT5XfWZdIXQU.jpg"}
        // ]}, 
        // { image: <SiSpringsecurity size="90px"  className='icon-color' />,  title: "Asset Management", link: "/page/asset",
        // smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/FBN9kjDCzeXOeCaUUq9BmqgyMdZ1YrPW1Uwk0QeE.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/FYOmm9BuO94o0FOQpSDZ1T9wRi5CvG6olN5Nq0Jg.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/iZrQ7xVC8J9zP1q5MYy7M8UuASk3xD2aeK52RYui.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/C7c1vvNG5ajG3PyIWPVcklKgIepj6xn60UxqlqGw.jpg"}
        // ] },
        // { image: <GiBoxUnpacking   size="95px"  className='icon-color'/>,   title: "Packaging & Logistics", link: "/page/packaging" ,
        // smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/1ygSGFM2e1GH66YZqvrmrcnZAXufanuOkxO7ra8V.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/bPNM0PrDyRqJJoi19e0e6gEZ09TOPjq3SjdQcBX3.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/TDW6uGsn6nDo8AYqevpBkJrdEmiti8Vpu5RQkyhG.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/fLP7c3JbOc3XVTJaUjChmDMy3TQxNlYM8rXsBVfJ.jpg"}
        // ]},
        // { image: <MdApartment  size="95px"  className='icon-color'/>,      title: "Real Estate & Projects	", link:"/page/realestate" ,
        // smallimages:[
        //   {img1:"https://admin.bstlglobal.com/storage/uploads/dke7RUZHF3ZFm6436lqZ6maNeOhHaqcmHYESDFAO.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/oED83WwN8TAAI0fq6vNAXNsikYyFrmJ6XOM2iR8g.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/vhROdanbssd3rEZa5VkKVWX5DSGKyuvQphdhSeZ1.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/lCLrprVhxYfuGL2qD0njjDklO2VNnr4Y7SWKcmyU.jpg"}
        // ]},
      //   { image: <AiFillDollarCircle size = '95px'  className='icon-color'/>,          title: "Financial Services", link: "/page/financial",
      //   smallimages:[
      //     {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
      //   ]
      //  },
      //  { image: <GiWireframeGlobe  size = '95px'  className='icon-color'/>,          title: "International Representation", link: "/page/food",
      //   smallimages:[
      //     {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
      //   ]
      //  },    
       { images: "/Asets/BSTL_Global_Company_Logos-20.png",          title: "ABB LLC", link: "/page/abb_llc",
       smallimages:[
         {img1:"/Asets/MicrosoftTeams-image (28).png"}
       ]
      }, 
      { images:"/Asets/BSTL_Global_Company_Logos-08.png",          title: "Hitachi Energy LLC", link: "/page/hitach",
      smallimages:[
        {img1:"https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/women-in-2023-header:64-25?wid=2880&hei=1125&fmt=webp-alpha&fit=crop%2C1",img2:"https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/women-in-2023?wid=960&hei=640&fmt=webp-alpha&fit=crop%2C1",img3:"https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/hitachi-energy-transnetbw-photo:16-9?wid=600&hei=338&fmt=webp-alpha&fit=crop%2C1",img4:"https://dynamic-assets.hitachienergy.com/is/image/hitachiabbpowergrids/getting-green-hydrogen-renewable-energy-sources-1958175274:16-9?wid=600&hei=338&fmt=webp-alpha&fit=crop%2C1"}
      ]
     }, 
     { images: "https://www.zaubacorp.com/sites/default/themes/bootstrap_subtheme/images/logo-Zauba.png", title: "Hyunet Private Ltd", link: "/page/hyunet",
     smallimages:[
       {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
     ],
    }, 
    { images: "/Asets/BSTL_Global_Company_Logos-12.png",title: "	Green Tech Energy &  Water LLC", link: "/page/green",
    smallimages:[
      {img1:"https://static.wixstatic.com/media/36cf3c_04adfaf3784c4428b3ac0dd6b5b2e15d~mv2.jpg/v1/fill/w_546,h_845,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/36cf3c_04adfaf3784c4428b3ac0dd6b5b2e15d~mv2.jpg",img2:"https://static.wixstatic.com/media/36cf3c_9813aa018a754fc6a86d5c846ba066d2~mv2.jpg/v1/fill/w_1609,h_600,fp_0.50_0.50,q_85,enc_auto/36cf3c_9813aa018a754fc6a86d5c846ba066d2~mv2.jpg",img3:"https://static.wixstatic.com/media/36cf3c_d90947b033e4444f9b68bb78bc234fcb~mv2.jpg/v1/fill/w_438,h_273,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/36cf3c_d90947b033e4444f9b68bb78bc234fcb~mv2.jpg",img4:"https://static.wixstatic.com/media/36cf3c_96e9247121714a8096eed0de179e717c~mv2.jpg/v1/fill/w_384,h_384,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/GTEW%20-%20Booth%20%231650.jpg"}
    ]
   }, 
   { images: "/Asets/BSTL_Global_Company_Logos-02 (1).png",    title: "Homeland  Plants LLC", link: "/page/homeland",
   smallimages:[
     {img1:"/Asets/MicrosoftTeams-image (34).png",img2:"https://homelandcontractorsva.com/images/Lawn_Care_Program_VA.jpg",img3:"https://homelandcontractorsva.com/images/PA-Stone-patio.jpg",img4:"https://homelandcontractorsva.com/images/washington-monument-sod.jpg"}
   ]
  }, 
  { images: "/Asets/BSTL_Global_Company_Logos-19.png" ,          title: "	Blue Aqua Singapore", link: "/page/blue_aqua_singarpur",
  smallimages:[
    {img1:"https://blueaquaint.com/wp-content/uploads/2022/07/iStock-586045998.jpg",img2:"https://blueaquaint.com/wp-content/uploads/2022/04/Singapore_-Smart-Trout-Farm.jpg",img3:"https://blueaquaint.com/wp-content/uploads/2023/02/220901-Blue-Aqua-20-2.png",img4:"https://blueaquaint.com/wp-content/uploads/2022/10/image_MinGro-plus-bag.jpg"}
  ]
 }, 
 { images: "/Asets/BSTL_Global_Company_Logos-19.png",          title: "Blue Aqua LLC", link: "/page/blue_aqua",
 smallimages:[
  {img1:"https://blueaquaint.com/wp-content/uploads/2022/07/iStock-586045998.jpg",img2:"https://blueaquaint.com/wp-content/uploads/2022/04/Singapore_-Smart-Trout-Farm.jpg",img3:"https://blueaquaint.com/wp-content/uploads/2023/02/220901-Blue-Aqua-20-2.png",img4:"https://blueaquaint.com/wp-content/uploads/2022/10/image_MinGro-plus-bag.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-17.png",title: "Apollo Hospital Muscat LLC", link: "/page/apollo_muscat",
smallimages:[
  {img1:"https://www.apollohospitalmuscat.com/images/promotions/promotion22703bbe5c1579ab953f45d062811544a484a83a.jpg",img2:"https://www.apollohospitalmuscat.com/images/promotions/promotionf4a45738f93dae51b91833e2e3acc5039a5c7471.jpg",img3:"https://www.apollohospitalmuscat.com/images/promotions/promotion0f70ae50ed5beea7b1badcd0039016450e03938a.jpg",img4:"https://www.apollohospitalmuscat.com/images/promotions/promotion51a9c968b84798a84f981ddfc4a3154f7b073011.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-17.png",  title: "International Medical Center LLC (Sugar Apollo)", link: "/page/international",
smallimages:[
  {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-17.png",  title: "Apollo Super Speciality Hospital LLC", link: "/page/super",
smallimages:[
  {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-18.png",  title: "	Ash Amaira Retail Private Limited", link: "/page/amaira_retail",
smallimages:[
  {img1:"https://www.ashamaira.com/cdn/shop/files/MG_0437_750x960_crop_center.jpg?v=1646638917",img2:"https://www.ashamaira.com/cdn/shop/files/MG_0343_750x960_crop_center.jpg?v=1646718833",img3:"https://www.ashamaira.com/cdn/shop/products/MG_0242_600x.jpg?v=1644916241",img4:"https://www.ashamaira.com/cdn/shop/files/MG_0415_700x.progressive.jpg?v=1646639103"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-18.png", title: "ASH Amaira Accessories Trading L.L.C - Dubai", link: "/page/accessories",
smallimages:[
  {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-14.png",          title: "General Dynamics Mission Systems-Gulf LLC", link: "/page/genral",
smallimages:[
  {img1:"	https://www.gdms-gulf.com/wp-content/uploads/2019/05/gd_italy_careers.jpg",img2:"	https://www.gdms-gulf.com/wp-content/uploads/2018/12/general_dynamics_tablet_screen.jpg",img3:"	https://www.gdms-gulf.com/wp-content/uploads/2019/02/Antennas-rev-3.png",img4:"https://www.gdms-gulf.com/wp-content/uploads/2019/05/gd_italy_management_team.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-11.png",  title: "Gulf Security Services LLC", link: "/page/gulf",
smallimages:[
  {img1:"https://gulfsecurityservices.com/uploads/aboutus/home_about_01.jpg",img2:"https://gulfsecurityservices.com/uploads/case_study/Hospitality_370x257_01.jpg",img3:"https://gulfsecurityservices.com/uploads/case_study/Industries_370x257.jpg",img4:"https://gulfsecurityservices.com/uploads/case_study/Smart_City_1170x360.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-13.png",  title: "	Bahwan Lamnalco SAOC", link: "/page/bahwan_lamnalco",
smallimages:[
  {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-10.png", title: "Rosenbauer MENA FZE (Austria)", link: "/page/rosenbauer",
smallimages:[
  {img1:"https://www.rosenbauer.com/mena/fileadmin/Middle_East/mena_web_pics2.jpg",img2:"https://www.rosenbauer.com/mena/fileadmin/Middle_East/mena_web_pics3.jpg",img3:"https://www.rosenbauer.com/mena/fileadmin/Middle_East/mena_web_pics1.jpg",img4:"https://www.rosenbauer.com/mena/fileadmin/Middle_East/mena_web_pics2.jpg"}
]
},
 { images: "/Asets/BSTL_Global_Company_Logos-16.png",  title: "Cantiere Navale Vittoria S.p.A. (Italy)", link: "/page/cantiere",
smallimages:[
  {img1:"https://www.vittoria.biz/wp-content/uploads/2022/10/Construction-Cantiere-Navale-Vittoria-navi-militari-commerciali-yacht-1.jpg",img2:"https://www.vittoria.biz/wp-content/uploads/2022/10/Construction-Cantiere-Navale-Vittoria-navi-militari-commerciali-yacht-4.jpg",img3:"https://www.vittoria.biz/wp-content/uploads/2022/10/Construction-Cantiere-Navale-Vittoria-navi-militari-commerciali-yacht-3.jpg",img4:"https://www.vittoria.biz/wp-content/uploads/2022/10/Construction-Cantiere-Navale-Vittoria-navi-militari-commerciali-yacht-5.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-15.png",   title: "Vanderlande Industries B.V. (Netherlands)", link: "/page/vanderlande",
smallimages:[
  {img1:"https://www.vanderlande.com/wp-content/uploads/2023/03/Building-60-dd-21_07_21-Nr_069.jpg",img2:"https://www.vanderlande.com/wp-content/uploads/2023/04/banner-09.jpg",img3:"https://www.vanderlande.com/wp-content/uploads/2023/11/De-Bondt-_FASTPICK_-photos_14809.jpg",img4:"https://www.vanderlande.com/wp-content/uploads/2023/04/AdobeStock_200259224.jpeg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-01.png", title: "	Loconav Inc. (USA)", link: "/page/loconav",
smallimages:[
  {img1:"https://loconav.com/static/45f0c858f10daeb17fd9f97f3ec97143/9e35f/protect-vehicle-new.webp",img2:"https://loconav.com/static/2369255be10bbebbfbf424c518135d05/6fbbd/enhance-fuel-economy-new.webp",img3:"https://loconav.com/static/1fa4ca144e0e58b623f6bde2a227731f/31e79/manage-drivers-new.webp",img4:"https://loconav.com/static/463ac7d95318b54d58d064084507a094/c6e40/industry1.webp"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-09 (1).png", title: "	Al Qantar International SPC", link: "/page/al_qantar",
smallimages:[
  {img1:"https://businessgateways.com/images/profile_ext/profilebanner.jpg",img2:"https://businessgateways.com/images/Products_cover/2023051409343322702.jpg",img3:"https://businessgateways.com/images/Products_cover/202305140925562918.jpg",img4:"https://businessgateways.com/images/Products_cover/2018032212160820639.jpg"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-06.png",  title: "Techno  Elevators LLC", link: "/page/techno",
smallimages:[
  {img1:"https://www.technoelevators.com/images/services-1.png",img2:"https://www.technoelevators.com/images/services-2.png",img3:"https://www.technoelevators.com/images/services-3.png",img4:"https://www.technoelevators.com/images/project_1.png"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-07.png",    title: "Intelligent Parking  & Elevators Co. LLC", link: "/page/intelligent",
smallimages:[
  {img1:"https://uaecompanies.ae/uploads/logos/thumbs/ipark-tower-20180315014150.jpg",img2:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXP2j0XLJzHHhUIALB5j_UQD9Dpy90-Gu_8A&usqp=CAU",img3:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCUGpCU718a74AvbEDyMvOchlSIteljNLIJg&usqp=CAU",img4:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQApYMg0JoMaj4Su9rLzXuD95L21XPy1Bwr3g&usqp=CAU"}
]
}, 
{ images:"/Asets/BSTL_Global_Company_Logos-07.png",  title: "I Park Robotic Parking and  Elevators L.L.C", link: "/page/ipark",
smallimages:[
  {img1:"https://uaecompanies.ae/uploads/logos/thumbs/ipark-tower-20180315014150.jpg",img2:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXP2j0XLJzHHhUIALB5j_UQD9Dpy90-Gu_8A&usqp=CAU",img3:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCUGpCU718a74AvbEDyMvOchlSIteljNLIJg&usqp=CAU",img4:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQApYMg0JoMaj4Su9rLzXuD95L21XPy1Bwr3g&usqp=CAU"}
]
}, 
{ images: "/Asets/BSTL_Global_Company_Logos-05.png",   title: "North Ocean Industries SPC", link: "/page/north",
smallimages:[
  {img1:"/Asets/inside_banner/north&ipark/north3.jpg",img2:"/Asets/inside_banner/north&ipark/north4.png",img4:"/Asets/inside_banner/north&ipark/north5.jpg",img3:"/Asets/inside_banner/north&ipark/north2.png"}
]
}, 

{ images: "/Asets/MicrosoftTeams-image (19).png",          title: "Sadad International LLC", link: "/page/sadad",
smallimages:[
  {img1:"https://admin.bstlglobal.com/storage/uploads/mlEbr7BAHtAq9MMt3jAktzMjiUMs2BccnCVWIMmV.jpg",img2:"https://admin.bstlglobal.com/storage/uploads/UX1J6FJHpel3LsTZkxR3SxwHe32Ylkn3g1ZR62uo.jpg",img3:"https://admin.bstlglobal.com/storage/uploads/Lu1xikkXGMA58tH9TLKQ9hPBtlWrXG0pgTmraFi7.jpg",img4:"https://admin.bstlglobal.com/storage/uploads/RZtbb9uqVrkuZdvEPCfum1WTqcu4hAPmGAlF2S9d.jpg"}
]
},
]
  return (
    <>
      <Container fluid className='mt-5 mb-5' >
        <Row  style={{padding:'0px 45px',display:'flex',justifyContent:'center'}} >
          <div>
        <h3 className='mb-4 text-center' style={{padding:'0px 0px 20px 0px',borderBottom:'2px solid black'}}>Our Business</h3>
        </div>
            {ourbusines.map((item)=>{
                return(
            <Col lg={2} className='flip-col'>
                <Link to={item.link}>
            <div className="flip-card">
        <div className="flip-card-inner">
        <div className="flip-card-front" style={{height:'100%'}}> 
        <div>
        <div  style={{height:'100px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        {item.images ? <img  src={item.images} width={'85%'} height={'auto'} />:
        item.image}
        </div> 
        <div style={{padding:'0px 10px 0px 10px',height:'70px'}}>

        {item.title === "Homeland  Plants LLC"   ? 
              <>
              <p className='title-name-back' style={{fontSize:'16px'}}> Homeland <br></br>Plants LLC</p>  
              </>
              :

          item.title === "Apollo Hospital Muscat LLC"? 

          <p className='title-name-back' style={{fontSize:'16px'}}> Apollo Hospital <br></br>Muscat LLC</p>  

          
          
          :

          item.title === "	Al Qantar International SPC"? 

          <p className='title-name-back' style={{fontSize:'16px'}}> Al Qantar <br></br> International SPC</p>  

          
          
          :
          item.title === "Rosenbauer MENA FZE (Austria)"? 

          <p className='title-name-back' style={{fontSize:'16px'}}> Rosenbauer MENA <br></br> FZE (Austria)</p>  

          
          
          :

          item.title === "	Ash Amaira Retail Private Limited" ? 

          <p className='title-name-back' style={{fontSize:'16px'}}> Ash Amaira Retail <br></br>Private Limited</p>  
  
          :

          item.title === "North Ocean Industries SPC" ? 

          <p className='title-name-back' style={{fontSize:'16px'}}> North Ocean <br></br>Industries SPC</p>  
  
          :

          item.title === "	Blue Aqua Singapore" ? 

          <p className='title-name-back' style={{fontSize:'16px'}}> Blue Aqua  <br></br>Singapore</p>  
  
          :
         

              <p className='title-name-back'  style={{fontSize:'16px'}} >
                
                { item.title}
                
                </p>}
        {/* <p className='title-name-front mt-4' style={{fontSize:'12px'}}>{item.title}</p> */}
        </div>
        

        </div>
        
        </div>
         
         
         
         
          <div className="flip-card-back">
            <div style={{paddingBottom:'5%'}}>

            <Row style={{padding:' 0px  25px'}}>
              {item.title === "Homeland  Plants LLC" ? 
              
            <p className='title-name-back' style={{fontSize:'11px'}}>Homeland <br></br>Plants LLC</p>
            :
            item.title === "ASH Amaira Accessories Trading L.L.C - Dubai" ? 
              
            <p className='title-name-back' style={{fontSize:'11px'}}>ASH Amaira Accessories  <br></br>Trading L.L.C - Dubai</p>
            :
            item.title === "General Dynamics Mission Systems-Gulf LLC" ? 
              
            <p className='title-name-back' style={{fontSize:'11px'}}>General Dynamics Mission  <br></br>Systems-Gulf LLC</p>
            :
            item.title === "Intelligent Parking  & Elevators Co. LLC" ? 
              
            <p className='title-name-back' style={{fontSize:'11px'}}>Intelligent Parking  &<br></br>Elevators Co. LLC</p>
            :

            item.title === "I Park Robotic Parking and  Elevators L.L.C" ? 
              
            <p className='title-name-back' style={{fontSize:'11px'}}>I Park Robotic Parking and <br></br> Elevators L.L.C</p>
            :



            <p className='title-name-back'  style={{fontSize:'11px',height:'33px'}} >
              
              { item.title}
              
              </p>}
 
     {item.smallimages && item.smallimages.map((item)=>{
      return(
        <>
              <Col lg={12} className='image-our'>
               <img  src={item.img1} width={'150px'} height={'150px'}  style={{objectFit:'cover'}} />
              </Col>
              {/* <Col lg={6} className='image-our'>
              <img  src={item.img2} width={'100%'} height={'80px'}  style={{objectFit:'cover'}} />
              </Col>
              <Col lg={6} className='image-our'>
              <img  src={item.img3} width={'100%'} height={'80px'}   style={{objectFit:'cover'}} />
              </Col>
              <Col lg={6} className='image-our'>
              <img  src={item.img4} width={'100%'} height={'80px'}  style={{objectFit:'cover'}} />
              </Col> */}
          </>  
            )
          })}
            </Row>
            <div>
            </div>
            
            </div>
           
          </div>
        </div>
      </div>
      </Link>
      
            </Col>
            )
        })}
          
         
        </Row>
      </Container>  
      
    </>
  );
};

export default CardFlip;
